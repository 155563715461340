/* Fonts */

@import url('https://fonts.googleapis.com/css?family=Yantramanav:100,300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Colors */

@import '_variables';

/* Basics */

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0 auto;
    width: 100%;
    height: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

body {
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-color: $background;
    color: $basetext;
}

.content-wrap {
    padding: 2rem 2rem 0 2rem;
}

h1 {
    font-family: 'Inter', sans-serif;
    color: $strongtext;
    font-size: 2.5rem;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 1rem;
}

h2 {
    font-family: 'Inter', sans-serif;
    color: $strongtext;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.4;
    width: 100%;
}

h3 {
    color: $strongtext;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.7;
    margin-top: 4px;
    display: inline-block;
    height: 24px;
    line-height: 24px;
}

a {
    text-decoration: none;
    color: $primary;
    position: relative;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        color: $primary;
    }

    /*

    &::after {
        content: '';
        position: absolute;
        width: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: -4px;
        transition: all .2s ease-out;
        border-bottom: 2px $primary solid;
    }

    &:hover::after {
        width: 100%;
    }

    */
}

hr {
    width: 100%;
    display: block;
    border: 0;
    margin: 0 0 2rem 0;
    border-top: $elevated solid 1px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

p.body {
    color: $basetext;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6;
}

p.body-lrg {
    color: $basetext;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.7;
    margin-bottom: 0;
}
/*
.shadow {
    box-shadow: $shadow;
}

.border {
    border: 1px solid #E8E8E8;
}
*/

.caption {
    display: block;
    color: $basetext;
    font-size: .95rem;
    padding-top: 1.25rem;
    width: 100%;
    line-height: 1.6;
    opacity: .7;
}

.work-stats .button {
    color: #fff;
    border-radius: 4px;
    background-color: $primary;
    box-shadow: $shadow;
    transition: all .15s;
    cursor: pointer;
    height: 3rem;
    line-height: 3rem;
    display: inline-flex;
    padding: 0 1.5rem;
}

.work-stats .button:hover:after {
    display: none
}

.work-stats .button:hover {
    transform: translateY(-1px);
    box-shadow: $shadowHover;
}

.work-stats .button:active {
    transform: translateY(0);
    box-shadow: none;
}

.back-arrow {
    position: relative;
    top: 6px;
}

@media only screen and (max-width: 1140px) {

    body {
        position: relative;
        padding: 0;
    }

    .project-wrap {
        padding: 0 1rem 2rem 1rem;
    }

    .content-wrap {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    h1 {
        margin-top: 8px;
        font-size: 2.1rem;
        line-height: 1.4;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 1.5rem;
        margin: 1rem 0 2rem;
    }

    h3 {
        margin-top: 0;
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }

    a:after {
        display:none;
    }
    
}

@media only screen and (max-width: 600px) {

    .body-lrg {
        font-size: 1.1rem !important;
        line-height: 1.6 !important;
    }
    
}

