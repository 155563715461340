@import '_variables';

.project-wrap {
    max-width: 1024px;
    padding-bottom: 4rem;

    p {
        margin-bottom: 2rem;
    }
}

.work-hero {
    margin: 3rem 0 2rem 0;

    img {
        display: block;
        width: 100%;
        border-radius: 4px;
    }

    video {
        width: 100%;
        border-radius: 4px;;
    }
}

.work-intro {
    position: relative;
    justify-content: space-between;
}

.work-title {
    width: 65%;

    h1 {
        color: white;
        margin: 0;
    }

    h3 {
        font-size: 1.1rem;
        line-height: 1.9;
        margin-top: .5rem;
        color: white;
    }
}

.work-stats {
    width: 31%;
    transform: translateY(-8rem) translateX(2rem);
    border-radius: 4px;
    padding: 2rem;
    background-color: $elevated;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: $shadow;
    
    .button {
        font-weight: 600;
        margin-top: .5rem;
    }

    .button svg {
        position: static;
        transform: translateY(12px);
        margin-left: 4px;
    }

    .stat-label {
        display: block;
        font-weight: 600;
        margin-bottom: .25rem;
        color: white;
        padding-left: 2.5rem;
    }

    .stat-content {
        display: block;
        margin-bottom: 1rem;
        color: $basetext;
        padding-left: 2.5rem;
    }

    .stat-content:last-child {
        margin-bottom: 0;
    }

    svg {
        position: absolute;
        transform: translateY(6px);
    }
}

.img-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;

    img {
        width: calc(60% - 32px);
        margin-right: 48px;
    }
}

.img-right {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;

    img {
        width: calc(60% - 48px);
        margin-left: 48px;
    }

}

@media only screen and (max-width: 1140px) {
    .work-intro {
        flex-direction: column;
    }

    .work-hero {
        margin-top: 3rem;
    }

    .first-hero {
        margin-top: 1rem;
    }

    .work-stats {
        width: 100%;
        border-radius: 4px;
        padding: 2rem;
        position: initial;
        display: block;
        transform: none;
        margin: 0 0 2.5rem 0;
        button {
            width: auto;
            padding: 0 2rem;
        }
    }

    .work-title {
        width: 100%;
    }

    .img-left,
    .img-right {
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
    }

    .img-left img,
    .img-right img {
        width: 100%;
        max-width: 40rem;
        margin: 2rem 0 1rem 0;
    }

    .img-left p,
    .img-right p {
        margin-bottom: 1rem;
    }
    
}