@import "_variables";

.footer {
    color: $basetext;
    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0 6rem;
    max-width: 1024px;
    width: 1024px;
}

.footer-spacer {
    margin-top: 6rem;
}

@media only screen and (max-width: 1140px) {

    .footer {
        div:first-of-type {
            margin-bottom: 24px;
        }
    }

    .footer-content {
        width: auto;
        flex-direction: column;
        text-align: center;
        padding: 2rem 1rem 2rem;
    }

    .footer-spacer {
        display: none;
    }

}
