@keyframes perspective {
    0% {
        transform: rotateX(0deg) rotateY(0deg)
    }

    20% {
        transform: rotateX(15deg) rotateY(15deg);
    }

    40% {
        transform: rotateX(15deg) rotateY(-15deg);
    }

    60% {
        transform: rotateX(-15deg) rotateY(15deg);
    }

    80% {
        transform: rotateX(0deg) rotateY(0deg);
    }
}

@keyframes draw {
    0% {
        stroke-dashoffset: 800;
    }

    20% {
        stroke-dashoffset: 0;
    }

    80% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 800;
    }
}

.hero {
    max-width: 1024px;;
}

.heroart {
    stroke: #7f74ff;
    stroke-width: 2;
    fill: none;
    height: 410px;
    width: 376px;
    position: absolute;
    right: 8px;
    bottom: 4px;
    transform: scale(.9);
    overflow: visible;
}

.square {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    animation: draw 4s cubic-bezier(.455, .03, .515, .955) infinite;
}

.circle {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    animation: draw 4s cubic-bezier(.455, .03, .515, .955) infinite;
    animation-delay: .2s;
}

.triangle {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    animation: draw 4s cubic-bezier(.455, .03, .515, .955) infinite;
    animation-delay: .4s;
}

.hero {
    margin: 4rem auto 0;
    display: flex;
}

.hero__right,
.hero__left {
    width: 50%;
    position: relative;
    min-height: 24rem;
}

@media only screen and (max-width: 1140px) {

    .hero {
        margin: 0 auto 0 auto;
        flex-direction: column;
        padding: 0 1rem 0 1rem;
    }

    .heroart {
        bottom: -75px;
        right: 20px;
    }

    .hero__right,
    .hero__left {
        width: 100%;
        min-height: 0;
    }

    .hero__right {
        display: none;
    }

    .heroart {
        position: relative;
    }
}

@media only screen and (max-width: 600px) {

    html, body {
        overflow-x: hidden;
    }

}
