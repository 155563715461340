@import '_variables';

.work-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.work {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    max-width: 1024px;
    padding-bottom: 4rem;

    .port-pod::after {
        display: none !important;
    }

    .port-pod {
        background-color: $elevated;
        box-shadow: $shadow;
        transition: all cubic-bezier(.25, .46, .45, .94) .150s;
        border-radius: 4px;
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 4px 4px 0 0;
        }

        div {
            padding: 1.25rem;
        }

        span {
            display: block;
        }

        .project-title {
            font-weight: 600;
            font-size: 1.1rem;
            margin-bottom: .5rem;
            color: $strongtext;
        }

        .project-role {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: .8rem;
            margin-bottom: 1rem;
            color: $basetext;
        }

        .project-description {
            line-height: 1.6;
            margin-bottom: .75rem;
            color: $basetext;
        }

        &:hover {
            transform: translateY(-4px);
            box-shadow: $shadowHover;
        }
    }
}

@media only screen and (max-width: 1140px) {

    .work {
        grid-template-columns: repeat(2, 1fr);
        padding: 3rem 1rem 0rem;
        gap: 24px;
    }

    .port-pod {
        &:hover {
            transform: translateY(0) !important;
            box-shadow: $shadow !important;
        }
    }

}

@media only screen and (max-width: 600px) {

    .work {
        grid-template-columns: 1fr;
        padding-top: 2rem;
    }

}