/* colors */

$primary: #7f74ff;
$background: #00041f;
$elevated: #12152b;
$basetext: rgba(255,255,255,.78);
$strongtext: white;

/* shadows */

$shadow:
0 10px 25px -5px rgba(0, 0, 0, .25),
0 5px 15px -10px rgba(0, 0, 0, .3),
0 -5px 15px -5px rgba(0, 0, 0, .1);

$shadowHover: 
0 15px 35px -5px rgba(0, 0, 0, .25),
0 10px 25px -10px rgba(0, 0, 0, .3),
0 0px 25px -5px rgba(0, 0, 0, .1);