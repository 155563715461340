.four_column {
    display: flex;
    justify-content: space-between;
    margin: 32px;
    div {
        width: 21%;
    }
    span {
        display: block;
        margin-bottom: 16px;
    }
    svg {
        margin-right: 8px;
        position: absolute;
        transform: translateY(4px) translateX(-32px);
    }
}

@media only screen and (max-width: 1140px) {
    .four_column {
        display: inline-block;
        /* width: 100%; */
        margin-top: 0;
        margin-bottom: 0;
    }
    .four_column div {
        width: 45%;
        margin-bottom: 24px;
        display: inline-table;
    }
    .four_column svg{
        margin-right: 8px;
        position: absolute;
        transform: translateY(0px) translateX(-32px) !important;
    }
}

@media only screen and (max-width: 600px) {
    .four_column div {
        width: 100%;
    }
}