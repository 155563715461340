@import '_variables';

// desktop nav
.logo {
    max-width: 1024px;
    width: 1024px;
    display: flex;
    align-content: start;
    margin: 0 auto;
}

.header-logo-link {
    margin-top: 2rem;
    text-decoration: none;
    color: white;
    font-size: 2rem;
    font-weight: 700;
    transition: opacity .1s;
    width: 100%;
}

.header-logo-link:hover {
    color: $primary;
}

.header-logo-link:after {
    display: none;
}

.header-wrap {
    margin-top: 2rem;
    max-width: 1024px;
    width: 100%;
    transition: border-color 300ms ease;
    border-bottom: 1px solid rgba(224, 224, 224, 0);
}

nav {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    font-size: .9rem;
}

nav a {
    display: flex;
    position: relative;
    float: left;
    align-items: center;
    transition: all .2s ease-out;
    padding-bottom: 3px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $basetext;
    font-weight: 500;
    height: 32px;
}

nav svg {
    float: right;
}

nav a:not(:last-child) {
    margin-right: 2.5rem;
}

nav a:hover {
    color: $primary;
}

nav a::after {
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: all .2s ease-out;
    border-bottom: 2px $primary solid;
    content: '';
}

// mobile nav
.mobile-menu,
.mobile-menu-btn,
.mobile-nav-dimmer {
    display: none;
}

@media only screen and (max-width: 1140px) {

    body {
        margin-top: 64px;
    }

    nav {
        display: none;
    }

    .header-logo-link {
        margin-top: 0rem;
    }

    .mobile-menu-btn {
        display: block;
        position: absolute;
        top: 1.35rem;
        right: 1rem;
        cursor: pointer;
        z-index: 3;
    }

    .menu-icon {
        overflow: visible;
    }

    .menu-icon line {
        transition: all 250ms cubic-bezier(.075, .82, .165, 1);
    }

    .menu-icon-rotate-top {
        transform: rotate(45deg) translateX(0) scaleX(1.41);
    }

    .menu-icon-rotate-bottom {
        transform: rotate(-45deg) translateX(0) scaleX(1.41);
    }

    .hide {
        opacity: 0;
    }

    .menu-icon-bottom {
        transform-origin: 0 16px;
    }

    .mobile-menu {
        display: block;
        position: fixed;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        background-color: $elevated;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 2rem 1rem;
        margin-top: 0;
        left: 0;
        right: 0;
        top: -3px;
        transform: translateY(63px);
        transition: all 150ms cubic-bezier(.55, .085, .68, .53);
        box-shadow: $shadow;
    }

    .mobile-menu a {
        margin-bottom: 2rem;
        font-size: 1rem;
    }

    .mobile-menu a:last-of-type {
        margin-bottom: 0;
    }

    .header-wrap {
        padding: 1rem 1rem 0 1rem;
        position: fixed;
        width: 100%;
        z-index: 1;
        margin-top: -64px;
        padding-bottom: 16px !important;
    }

    .header-logo-link {
        font-size: 1.5rem;
    }
}
